.root {
  background-color: #202020 !important;
  border-radius: 5px;
  padding: 15px;

  &::before {
    background-color: #202020 !important;
  }
}

.closeButton {
  color: var(--mantine-color-white) !important;
}
.closeButton:hover > svg {
  color: black;
}
