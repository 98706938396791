.highlight {
  color: #000;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  transform: translateY(2px);
}

.highlight-end {
  display: flex;
  flex-direction: column;
  align-items: end;
  transform: translateX(-60px) translateY(2px);
}

.appended-div {
  font-weight: 400;
}
