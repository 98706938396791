.bar,
.track::before {
  border-radius: 0px;
}

.mark {
  width: 3px;
  height: 30px;
  background: #3d7f53;
  border: #3d7f53;
  transform: translateX(5px) translateY(-9px);
}

.markLabel {
  font-size: var(--mantine-font-size-xs);
  margin-bottom: 5px;
  margin-top: 0;
}

.thumb {
  height: 20px;
  width: 20px;
  background-color: var(--mantine-color-white);
  border-width: 1px;
  box-shadow: var(--mantine-shadow-sm);

  &[data-dragging] {
    height: 25px;
    width: 25px;
    background-color: #3d7f53;
    box-shadow: 0 0 0 10px #3d7f5330;
  }
}

.thumb:hover {
  height: 25px;
  width: 25px;
}
