.button,
.shoppingButton {
  padding: 0px;

  &:hover {
    background: #ccc !important;
  }
}

.mobileSticky {
  justify-content: flex-end;
  display: flex;
  position: sticky;
  width: 100%;
  bottom: 100px;
}

.shoppingButton {
  box-shadow:
    0 8px 10px 0 rgba(0, 0, 0, 0.2),
    0 0px 10px 0 rgba(0, 0, 0, 0.19);
}

.navBtnText {
  width: 100%;
  font-size: 16px;
  line-height: 18px;
  text-align: left;
  margin-right: 15px;
  word-break: break-word;
  white-space: normal;
}

.icon {
  width: 28px;
  height: 28px;
  margin-left: 10px;
  fill: #3d7f53;
}

.navBar {
  position: sticky;
  bottom: 0;
  right: 0;
  left: 0;
  height: 200px;
  flex-direction: column;
  padding: 10px;
  height: fit-content;
  z-index: 200;
}

.innerNavBar {
  flex-direction: column;
  justify-content: space-between;
  gap: 15px;
  height: 100%;
  background: #f7f6f1;
  margin: 10px 10px 10px 10px;
  box-shadow: 0px -2px 10px 0px #0000000d;
  box-shadow: 0px 2px 10px 0px #00000026;
  padding: 10px 5px;
  border-radius: 12px;
}
