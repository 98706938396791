.checkoutBtn {
  background: #3d7f53;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 5px 13px;
}

.title {
  font-weight: 600;
  line-height: 24px;
  font-size: 21px;
}

.textContainer {
  padding: 0px 20px;
  justify-content: space-between;
}

.subText {
  font-size: 14px;
  line-height: 21px;
  color: #4a4a48;
}
