.title {
  font-weight: 500;
}

.box {
  box-sizing: border-box;
  background-color: #fff;
  border-radius: 4px;
  padding: 40px;
}

.flexBox {
  display: flex;
  gap: 40px;
  flex-wrap: wrap;
  justify-content: center;

  box-sizing: border-box;
  background-color: #fff;
  border-radius: 4px;
  padding: 40px;
}

.header {
  font-weight: 500 !important;
  font-size: 21px !important;
  line-height: 31px !important;
}

.firstParagraph {
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  color: #202020 !important;
}

.highlight {
  font-weight: 700 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  color: #3d7f53 !important;
}

.secondParagraph {
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 21px !important;
  color: #4a4a48 !important;
}
