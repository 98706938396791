.baseText,
.nameText {
  line-height: 24px;
  color: #4a4a48;
}

.baseText {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.nameText {
  font-size: 21px;
  font-weight: 600;
}

.noDataBox {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 0px 0px 20px;
  width: 100%;
}
