.container {
  width: 100vw;
  height: auto;
  background: #fff;
}

.contentWrapper {
  max-width: 1500px;
}

.introContainer {
  padding: 0px 0px 0px 0px;
  flex-direction: column;
  justify-content: center;
  background: #fff;
  max-width: 1100px;
}

.sectionWrapper {
  gap: 20px;
  flex-direction: column;
  padding: 20px;
}

@media screen and (max-width: 550px) {
  .sectionWrapper {
    padding: 0px;
  }
}

/* ---- Sections ---- */
.baseSection {
  padding: 70px;
  border-radius: 8px;
  max-width: 1100px;
  align-items: center;
  text-align: center;
}

@media screen and (max-width: 600px) {
  .baseSection {
    padding: 40px 20px 40px 20px;
    border-radius: 0px;
    align-items: flex-start;
    text-align: left;
  }
}

@media screen and (max-width: 1100px) {
  .baseSection {
    max-width: 100vw;
  }
}

/* ---- Intro ---- */
.introBox {
  flex-direction: column;
  gap: 20px;
  padding: 20px 0px 50px 0px;
  z-index: 1;
  align-items: center;
}

.introTitle {
  font-size: 48px;
  font-weight: 600;
  line-height: 57.6px;
  letter-spacing: -0.5px;
  text-align: center;
  padding: 10px;
  color: #202020;
}

.introImg {
  max-width: 1100px;
  z-index: 0;
}

@media (max-width: 1000px) {
  .introBox {
    padding: 20px;
  }
}

@media (max-width: 600px) {
  .introImg {
    border-radius: 0px;
  }
  .introBox {
    padding: 0px;
  }
  .introTitle {
    font-size: 35px;
    line-height: 40px;
    padding: 0px 15px;
  }
}

/* ---- Stepper ---- */

.stepIcon {
  border: 1px solid #3d7f53 !important;
  color: #3d7f53 !important;
  background-color: transparent !important;
  font-weight: 500 !important;
}

.verticalSeparator {
  border-color: #3d7f53 !important;
  border-width: 1px !important;
}

.stepBody {
  padding: 0px 0px 20px 20px !important;
}

.stepLabel {
  line-height: 40px !important;
  font-size: 21px !important;
  color: #202020 !important;
}
.stepDescription {
  line-height: 24px !important;
  font-size: 16px !important;
  color: #4a4a48 !important;
}

/* ---- how it works ---- */
.howItWorksContainer {
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  height: 100%;
  text-align: left;
  flex: 1 0 44%;
  align-self: start;
}

/* ---- faqshort ---- */
.faqTitle,
.howItWorksTitle {
  font-size: 50px;
  font-weight: 500;
  line-height: 64px;
}

@media (max-width: 600px) {
  .faqTitle {
    font-size: 28px;
    font-weight: 600;
    line-height: 38px;
  }
  .howItWorksTitle {
    font-size: 28px;
    line-height: 34px;
  }
}

/* ---- cooperations ---- */

.universityImg {
  height: 70px;
}

.zurichImg {
  height: 74px;
}

.qbImg {
  height: 60px;
}

.wrapper {
  gap: 70px;
  padding: 10px;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

@media (max-width: 800px) {
  .universityImg,
  .qbImg {
    height: 40px;
  }

  .zurichImg {
    height: 50px;
  }
  .wrapper {
    gap: 30px;
  }
}

/* testimonials */
.slide {
  padding: 30px;
  border-radius: 5px;
  background: #f1f0e899;
  height: 100%;
  width: 100%;
  gap: 20px;
  justify-content: space-between;
  flex-direction: column;
}

/* ---- join and profit ----*/
.leftContainer {
  flex-direction: column;
  gap: 40px;
  align-items: flex-start;
  text-align: left;
  height: 100%;
  flex: 1;
}
