.filled {
  border: 2px solid #3d7f53;
  background-color: #f7f6f1;

  &:disabled,
  &[data-disabled] {
    border: 2px solid #7f898f;
    background-color: #f5f5f5;
  }
}

.greyed {
  border: 1.5px solid #7f898f;
  background: #f5f5f5;

  &:disabled,
  &[data-disabled] {
    border: 1.9px solid #7f898f;
    background-color: #f5f5f5;
  }
}
