.container {
  max-width: 100%;
  width: 100%;
}

.contentWrapper {
  width: 850px;
  border-radius: 5px;
}

.appointmentBox {
  border-radius: 5px 0px 0px 5px;
  flex: 1;
  background: #f1f0e899;
  padding: 20px 30px;
  gap: 10px;
  flex-direction: column;
  min-width: 340px;
}

.mapView {
  flex: 1 30%;
  min-width: 270px;
  min-height: 300px;
  background: #f1f0e899;
}

@media screen and (max-width: 600px) {
  .accordionItem {
    padding: 0px 20px;
  }
}
