.container {
  margin: auto;
  padding: 48px;
}

.smallContainer {
  margin: auto 20px;
  padding: 30px 16px;
}

.container,
.smallContainer {
  align-items: center;
  flex-direction: column;
  max-width: 500px;
  border-radius: 5px;
  background: #fff;
}

.stepContainer {
  display: flex;
  gap: 10px;
  flex-direction: column;
}
