/* ---- footer ---- */
.footerContainer {
  width: 100%;
  background: #202020;

  bottom: 0;
  left: 0;
  right: 0;

  z-index: 100;
  padding: 0px 15px;

  flex-direction: column;
}

.labelWrapper {
  width: auto;
  height: 3.5em;

  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
  gap: 0px 10px;
}

.label {
  text-decoration: none;
  color: #ffffffcc;
  font-size: 14px;
  line-height: 18px;
}
