.baseContainer {
  max-width: 100%;
  width: 100%;
  height: auto;
}

.registerBtn {
  width: 160px;
  margin: 12px 0px;
}

/* ---- wrapper ---- */
.wrapper,
.smallWrapper {
  background: #fff;
  padding: 60px;
  border-radius: 5px;
  height: 100%;
}

.wrapper {
  margin: 40px;
  width: auto;
  padding: 60px;
}

.smallWrapper {
  margin: 0px;
  width: 100%;
  padding: 20px;
}

/* ---- title ---- */
.smallTitle {
  text-align: start;
}

.smallTitle,
.title {
  font-weight: 500 !important;
  font-size: 28px !important;
}
