.horizontal {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.box {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  flex-wrap: wrap;
  width: fit-content;

  gap: 24px;
  border-radius: 5px;
  border: 1px solid #d0cdb1;
  background-color: #f1f0e899;
}

@media screen and (max-width: 550px) {
  .box {
    text-align: center;
    justify-content: center;
  }
}
