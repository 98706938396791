.container {
  display: flex;
  flex-direction: row;
  padding: 20px;
  justify-content: center;
}

@media screen and (max-width: 600px) {
  .container {
    flex-wrap: wrap;
  }
}

.textColumn {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.headerText {
  font-weight: 600;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
}

.bodyText {
  font-weight: 400;
  letter-spacing: 0px;
  text-align: left;
}

.imageContainer {
  margin: 8px 12px 10px 0px;
}
