.container {
  max-width: 100%;
  width: 100%;
  height: 100%;
}

.centerContainer {
  height: 100%;
  padding: 20px;
}

.checklistHeader {
  flex-direction: column;
  gap: 5px;
  background: #fff;
  padding: 20px;
  border-radius: 5px 5px 0px 0px;
}

.checklistSection {
  flex-direction: column;
  background: #fff;
  border-radius: 0px 0px 5px 5px;
  padding: 0px 20px 20px 20px;
}

.spaceItem {
  background: #fff;
}

.appointmentItem {
  text-align: left;
  color: #fff;
  padding: 0px 20px;
  margin: 20px 0px 0px 0px;
  font-weight: 400;
}

.appointmentContainer {
  background: #fff;
}

@media screen and (max-width: 600px) {
  .centerContainer {
    padding: 0px;
  }

  .checklistHeader {
    flex-direction: column;
    gap: 5px;
    background: #f1f0e8;
  }

  .appointmentItem {
    color: #fff;
    margin: 20px;
  }

  .checklistSection {
    padding: 0px;
  }

  .accordionItem {
    padding: 0px 20px;
  }

  .appointmentContainer {
    text-align: center;
  }
}

/* ---- ChecklistItemView ---- */
.stepperButton {
  padding: 0px !important;
  height: 30px !important;
  width: 30px !important;
}

.accordion {
  background: #fff;
  padding: 10px !important;
  flex: 1;
}

.stepIndex {
  line-height: 23px;
  margin-top: 1px !important;
}
