.card {
  padding: 32px;
  background: #f7f6f1;
  border: 1px solid #d0cdb1;
  border-radius: 8px;
}

.smallCard {
  padding: 16px;
  background: #f7f6f1;
  border: 1px solid #d0cdb1;
  border-radius: 8px;
}
