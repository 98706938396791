.container {
  max-width: 100%;
  width: 100%;
  height: calc(100vh - 3.5em);
  /* spacing for the footer */
}

.centerContainer {
  height: 100%;
}

.contentWrapper {
  width: 450px;
  padding: 20px;
  text-align: center;
  border-radius: 5px;
}

.borderRadius {
  border-radius: 5px;
}

/* ---- not eligible ---- */
.rejectMessage {
  flex: 1;
  gap: 30px;
  align-items: center;
  flex-direction: column;
  max-width: 50%;
  margin: 0px 20px;
}

.rejectImage {
  min-width: 100px;
  max-width: 120px;
  margin: 0px 0px 20px 0px;
}

.clubBox,
.clubBoxSmall {
  background-color: #fff;
  flex-direction: column;
  flex: 1;
}

.clubBoxSmall {
  padding: 20px;
  max-width: 100%;
  margin: 0px;
  height: 100%;
  border-radius: 5px;
  width: 100%;
}

.clubBox {
  padding: 60px;
  max-width: 500px;
  margin: 20px;
  height: auto;
  border-radius: 5px;
  width: 50%;
}
