.scrollArea {
  /* 100vh - header - breadcrumbs */
  height: calc(100vh - 179.5px - 3.5em);
  margin: 0px 5px;
}

.onboardingCont {
  flex-direction: column;
  max-width: 1100px;
  gap: 20px;
  padding: 15px;
}

.wrapper {
  flex-direction: column;
  max-width: 1100px;
  background: #fff;
  border-radius: 5px;
}

.questionnaireWrapper {
  flex-direction: column;
  max-width: 1100px;
  gap: 20px;
  width: 100%;
  background: #f1f0e8;
}
