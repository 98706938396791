.body {
  /* padding: 20px; */
  margin: 10px 0px;
  background: #f7f6f1;
  border-radius: 8px;
  border: 0px solid #3d7f53;
}

.labelWrapper {
  width: 100%;
}

.label {
  width: 100%;
  padding: 20px 0px;
}

.inner {
  margin: 20px;
}
