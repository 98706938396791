.root {
  flex: 1;
  width: 100%;
  background-color: #f5f5f5;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #b1b1b1;
}

.label {
  color: #202020;
}
