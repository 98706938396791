.clickableItem {
  position: relative;
}
.clickableItem:hover {
  cursor: pointer;
}

.notesIcon {
  position: absolute;
  right: 5px;
  top: 5px;
}
