.stepIcon {
  background: #fff;
  border-color: #3d7f53 !important;
  color: #3d7f53;
  line-height: normal;
  font-weight: 500;

  &[data-completed] {
    background: #3d7f53;
    color: #fff;
  }

  &[data-progress] {
    background: #3d7f53;
    color: #fff;
  }
}

.separator {
  background: #b1b1b1 !important;
}

.verticalSeparator {
  border-color: #b1b1b1 !important;
}

.root {
  display: flex;
  flex-direction: column-reverse;
}

.stepDescription {
  line-height: 20px;
}
