.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  margin: 15px;
  color: #505d68;
  align-items: center;
  gap: 25px;
}

.buttonContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.content {
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
}
.button {
  align-self: center;
}

.logo:hover {
  cursor: pointer;
}
