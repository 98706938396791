.button {
  padding: 0px;
  width: 100%;

  &:hover {
    background: #f1f0e8;
  }
}

.navBtnText {
  width: 100%;
  font-size: 16px;
  line-height: 18px;
  text-align: left;
  word-break: break-word;
  white-space: normal;
}

.icon {
  width: 28px;
  height: 28px;
  margin-left: 10px;
  margin-right: 10px;
  fill: #3d7f53;
}

.navBar {
  background: #fff;
  flex-direction: column;
  padding: 10px 5px;
  border-radius: 5px;
  gap: 15px;
  height: 100%;
}

.innerNavBar {
  flex-direction: column;
  justify-content: space-between;
  gap: 15px;
  height: 100%;
}

/* mobile navbar */
.mobileNavBar {
  background: #f7f6f1;
  flex-direction: column;
  padding: 10px 5px;
  border-radius: 12px;
  gap: 15px;
  height: 100%;
  margin: 10px;
  box-shadow: 0px -2px 10px 0px #0000000d;
  box-shadow: 0px 2px 10px 0px #00000026;
}

.mobileButton {
  padding: 0px;
  width: 100%;

  &:hover {
    background: #f1f0e8;
  }
}
