.list > * {
  margin: 1em 0 1em 0;
}

.card,
.cardImportant {
  gap: 8px;
  text-align: center;
  padding: 24px 16px 24px 16px;
}

.cardImportant {
  border: 1px solid #eb0037;
}
