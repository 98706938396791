.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;

  margin: 1rem auto;
  padding: 0.7rem;
  border: dotted 3px darkred;
}

.childrenContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 10px;
  flex-wrap: wrap;
}
