.header {
  gap: 20px;
  padding: 20px 20px 0px 20px;
  align-content: flex-start;
}

.headerTitle {
  font-weight: 500;
  font-size: 28px;
  line-height: 38px;
}

.icon {
  width: 38px;
  height: 38px;
}

.close {
  position: absolute;
  top: 15px;
  right: 15px;
}

.contentContainer {
  flex-direction: column;
  gap: 20px;
  padding: 0px 20px 20px 20px;
}

.buttonContainer {
  gap: 15px;
  padding: 20px 0px 0px 0px;
}

@media screen and (max-width: 768px) {
  .contentContainer {
    padding: 0px 5px 5px 5px;
  }
}
