.container {
  max-width: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 0px;
}

.borderRadius {
  border-radius: 5px;
}

.contentWrapper {
  flex: 1;
  max-width: 900px;
  border-radius: 5px;
  padding: 0px;
  flex-wrap: wrap;
}

.icon {
  fill: #7f898f;
  height: 20px;
  width: 20px;
}

.headerText {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  background: #fff;
}

.chip {
  fill: #fff;
  height: 17px;
  width: 17px;
}

.headerBox {
  gap: 20px;
  flex-direction: column;
  background: #fff;
  padding: 5px 0px;
}

.leftSide {
  min-width: 280px;
  flex-direction: column;
  justify-content: flex-start;
  background: #fff;
  padding: 20px 0px 30px 20px;
  flex: 1 35%;
  border-radius: 5px 0px 0px 5px;
}

@media screen and (max-width: 650px) {
  .contentWrapper {
    padding: 20px 0px;
  }
  .headerBox {
    padding: 20px;
    background: #f1f0e8;
  }
  .headerText {
    background: #f1f0e8;
  }
  .leftSide {
    padding: 0px;
    border-radius: 0px;
  }
}

/* ---- MapWrapper ---- */
.mapView {
  flex: 1 50%;
  min-width: 270px;
  min-height: 300px;

  background: #fff;
  padding: 20px;
  width: auto;

  min-height: 450px;
  padding: 20px;
  border-radius: 0px 5px 5px 0px;
}

@media screen and (max-width: 650px) {
  .mapView {
    min-height: 300px;
    padding: 10px;
    border-radius: 0px;
  }
}

/* ---- BookingItem ---- */
.itemContainer {
  flex-direction: column;
  align-content: start;
  justify-content: space-between;
  padding: 20px;
  gap: 30px;

  border-width: 0px 0px 0px 4px;
  border-style: solid;
}
