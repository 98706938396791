.container {
  width: 100vw;
  height: auto;
  background: #fff;
}

.title {
  font-size: 50px;
  font-weight: 500;
  line-height: 64px;
  color: #202020;
  text-align: center;
}

.topTitle {
  font-size: 21px;
  font-weight: 700;
  line-height: 24px;
  color: #3d7f53;
}

.accordionWrapper {
  max-width: 900px;
  flex-direction: column;
  padding: 0px;
  width: 100%;
}

.titleText {
  font-size: 28px;
  font-weight: 500;
  line-height: 38px;
  color: #202020;
  text-align: start;
}

.wrapper {
  flex-direction: column;
  gap: 16px;
  background-color: #f1f0e8;
  padding: 70px 20px 70px 20px;
  align-items: center;
}

.accordionContainer {
  flex-direction: column;
  gap: 46px;
  background-color: #fff;
  padding: 40px 20px;
  align-items: center;
  margin: -20px 0px;
}

.bottomSection {
  background: #fff;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  padding: 40px 20px 130px 20px;
  text-align: center;
}

@media screen and (max-width: 550px) {
  .title {
    font-size: 28px;
    line-height: 38px;
    font-weight: 600;
  }

  .topTitle {
    font-size: 16px;
  }

  .bottomSection {
    text-align: left;
    align-items: flex-start;
  }
}
